	import React from 'react';
	import * as Icon from 'react-feather';
	import {Link} from 'gatsby';

	const ContactInfo = () => {
		return (
			<div className="contact-info-area ptb-80">
				<div className="container">

					<div className="row justify-content-center">
						<div className="col-lg-4 col-md-6 col-sm-6">
						<a style={{width: "100%"}} href="mailto:contact@fenuahosting.com">
							<div className="contact-info-box">
								<div className="icon">
									<Icon.Mail />
								</div>
								<h3>Mail</h3>
								<p><a href="mailto:contact@fenuahosting.com">contact@fenuahosting.com</a></p>
							</div>
							</a>
						</div>

						<div className="col-lg-4 col-md-6 col-sm-6">
						<a style={{width: "100%"}} href="tel:+33695004694">
							<div className="contact-info-box">
								<div className="icon">
									<Icon.Phone />
								</div>
								<h3>What's App</h3>
								<p><a href="tel:+33695004694">(+33) 06 95 00 46 94</a></p>
							</div>
						</a>
						</div>

						<div className="col-lg-4 col-md-6 col-sm-6">
							<a style={{width: "100%"}} href="https://discord.gg/dGscPuHNAS">
								<div className="contact-info-box">
								<div className="icon">
									<Icon.MessageCircle />
								</div>
								<h3>Discord</h3>
								<p><a href="https://discord.gg/dGscPuHNAS">FenuaHosting</a></p>
							</div>
							</a>
						</div>
					</div>
				</div>
			</div>
		)
	}

	export default ContactInfo;  